import { Component, OnDestroy, OnInit } from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { DxValidatorModule } from "devextreme-angular/ui/validator";
import notify from "devextreme/ui/notify";
import { AppSettings } from "src/app/app-settings";
import { confirm } from "devextreme/ui/dialog";

@Component({
  selector: "app-create-invoice",
  templateUrl: "./create-invoice.component.html",
  styleUrls: ["./create-invoice.component.scss"],
})
export class CreateInvoiceComponent
  extends SharedClassComponent
  implements OnInit, OnDestroy
{
  now: Date = new Date();
  tomorrow_Date: Date = new Date();
  invoiceCreateForm: FormGroup;
  maxLength = 500;
  amount = 0;
  selectedCurrency = "TZS";
  InitialCurrency = "TZS";
  endpoint = "bills/create";
  currencyList = ["TZS", "USD"];
  billItems = [];
  currencyDts = [];
  paymentTypeDts = [
    {
      id: "EXACT",
      text: "EXACT",
    },
    {
      id: "FULL",
      text: "FULL",
    },
    {
      id: "PARTIAL",
      text: "PARTIAL",
    },
    {
      id: "INFINITY",
      text: "INFINITY",
    },
    {
      id: "LIMITED",
      text: "LIMITED",
    },
  ];
  monthList = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  revenueDataSource = this.authService.getCategoriesDetails();
  billItemsDatasource = [];
  createdInvoiceDatasource: any;

  institutionName = this.authService.getUserDetails().spName;
  institutionCode = this.authService.getUserDetails().spId;
  title = "Create Bill";
  spCatList = [];
  showInvoiceAlert = false;
  showBillItemsDialog = false;
  cityPattern = "^[^0-9]+$";
  namePattern: any = /^[^0-9]+$/;
  phonePattern: any = /^[0-9]+$/;
  countries: string[];
  phoneRules: any = {
    X: /[0-9]/,
  };
  invoiceId = null;
  catId: number = 0;
  cusno = null;
  hideUpdateBillButton = true;
  editBillDatasource: any;
  accDistributionDatasource = [];
  totalAmountBilled: number = 0;

  hideBillUpdateCategory = true;
  maxValue: number = 0;
  customerList: any[] = [];
  customerServiceList: any[] = [];
  isVIPRevenueSource: boolean = false;
  showControlNumber: boolean = false;
  createdBillDatasource = [];
  showBillPendingAlert: boolean;
  hideAmount: boolean = false;
  isAllowedToAdd: Boolean = false;
  isAllowedToUpdate: Boolean = false;

  isAllowedToDelete: Boolean = false;
  vipId: number = 142202250002;
  conAviationfuelGroundCatID: number = 142201120042; // prev 43
  rentalFeeCatID: number = 141501130003; // prev 41 not exctly

  conDutyFreeShopServCatID: number = 142201120043;
  conAirCraftcateringServiceServCatID: number = 142201120044;
  conOtherGroindHandlerCatID: number = 142201120045;
  conOtherShopAndServCatID: number = 142201120046;
  Invoice_number: any;
  TotalAmountTZS: number = 0;
  TotalAmountUSD: number = 0;

  ngOnInit() {
    this.tomorrow_Date.setDate(this.now.getDate() + 1);
    this.getServiceProviderAccounts();
    this.getCategoryBySp();
    this.getCustomerList();
    this.getCurrency();
    this.appInfo.setTitle(this.title);
    this.invoiceCreateForm = new FormGroup({
      payerNumber: new FormControl("", Validators.compose([])),
      payerName: new FormControl("", Validators.compose([Validators.required])),

      email: new FormControl(
        "",
        Validators.compose([
          Validators.email,
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/),
        ])
      ),
      phone: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(07|06)[0-9]{8}$/),
          Validators.minLength(10),
          Validators.maxLength(10),
        ])
      ),
      billExpireDate: new FormControl(
        this.tomorrow_Date,
        Validators.compose([])
      ),

      currency: new FormControl("", Validators.compose([Validators.required])),
      paymentType: new FormControl(
        this.paymentTypeDts[0].id,
        Validators.compose([Validators.required])
      ),

      billDescription: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      revenueSource: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      institutionName: new FormControl(
        { value: this.institutionName, disabled: true },
        Validators.compose([])
      ),
      institutionCode: new FormControl(
        { value: this.institutionCode, disabled: true },
        Validators.compose([])
      ),
      invoiceDate: new FormControl(this.now, Validators.compose([])),
      billAmount: new FormControl(null, Validators.compose([])),
      month: new FormControl("", Validators.compose([])),
      year: new FormControl("", Validators.compose([])),
    });

    this.route.queryParams.subscribe((params) => {
      this.invoiceId = params.invoiceId;

      this.cusno = params.cusno;
      this.catId = params.catId;
      if (this.invoiceId) {
        this.hideUpdateBillButton = false;
        this.getInvoiceById(this.invoiceId);
      } else {
        this.hideUpdateBillButton = true;
      }

      if (this.cusno && this.catId) {
        this.updateValue();
      }
    });

    if (!this.invoiceId) {
      // sessionStorage.removeItem(AppSettings.billEditKey);
    }
  }

  getServiceProviderAccounts() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;

            this.isAllowedToUpdate = pemsn.some(
              (permission) =>
                permission.permission === "Can Update Invoice" &&
                permission.permissionName === "true"
            );

            this.isAllowedToDelete = pemsn.some(
              (permission) =>
                permission.permission === "Delete Invoice" &&
                permission.permissionName === "true"
            );
            this.isAllowedToAdd = pemsn.some(
              (permission) =>
                permission.permission === "Create Invoice" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  ngOnDestroy(): void {
    // sessionStorage.removeItem(AppSettings.billEditKey);
  }

  updateValue() {
    const id = this.catId ? Number(this.catId) : this.catId;
    this.invoiceCreateForm.get("revenueSource").setValue(id);
    this.invoiceCreateForm.get("payerName").setValue(this.cusno);

    // if (
    //   id == this.vipId ||
    //   id === this.conDutyFreeShopServCatID ||
    //   id === this.conAirCraftcateringServiceServCatID ||
    //   id === this.conOtherGroindHandlerCatID ||
    //   id === this.conOtherShopAndServCatID
    // ) 
    if (
      id == this.vipId
    ) {
      this.hideAmount = true;
    } else {
      this.hideAmount = false;
    }

    if (id == this.vipId) {
      this.isVIPRevenueSource = true;
    } else {
      this.isVIPRevenueSource = false;
      this.customerServiceList = [];
    }
    this.getCustomeInformantion(this.cusno);
    if (
      id == this.vipId &&
      this.invoiceCreateForm.get("year").value &&
      this.invoiceCreateForm.get("month").value
    ) {
      this.getCustomerServiceList();
    }
  }

  getInvoiceById(invoiceId) {
    const data = {
      requestType: "INVOICES_GET",
      invoiceId: invoiceId,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          if (!response.data) {
            return;
          }

          const data = response.data[0];
          this.Invoice_number = data.INVOICE_NUMBER;
          const payerName = this.getCustomerNumber(data.PAYER_NAME);
          this.invoiceCreateForm.patchValue({
            revenueSource: data.REVENUE_SOURCE_ID
              ? Number(data.REVENUE_SOURCE_ID)
              : 0,
            billDescription: data.DESCRIPTION,
            invoiceDate: data.INVOICE_DATE,
            month: data.INVOICE_MONTH,
            currency: data.CURRENCY,
            year: data.INVOICE_YEAR,
            phone: data.PAYER_MOBILE,
            email: data.PAYER_EMAIL,
            billAmount: data.AMOUNT,
            payerName: payerName,
          });
          if (data.REVENUE_SOURCE_ID == this.vipId) {
            this.isVIPRevenueSource = true;
          }
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getCustomerServiceList() {
    if (!this.invoiceCreateForm.get("payerName").value) {
      this.toastr.error("Payer Name is Required");
      return;
    }
    this.customerServiceList = [];
    let data;
    let isServiceVip = false;
    if (this.invoiceCreateForm.get("revenueSource").value == this.vipId) {
      if (
        !this.invoiceCreateForm.get("month").value ||
        !this.invoiceCreateForm.get("year").value
      ) {
        this.toastr.error("Month and year are Required");
        return;
      }

      isServiceVip = true;

      data = {
        requestType: "SP_CUSTOMERS_SERVICE_RECEIVERS_LIST_BY_RANGE",
        month: this.invoiceCreateForm.get("month").value,
        year: this.invoiceCreateForm.get("year").value,
        custNo: this.invoiceCreateForm.get("payerName").value,
      };
    } else {
      data = {
        requestType: "GET_SERVICE_RECEIVERS_LIST",
        customerNo: this.invoiceCreateForm.get("payerName").value,
      };
    }

    if (!data) {
      return;
    }
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.customerServiceList = response.data;

          if (isServiceVip && this.customerServiceList.length != 0) {
            this.TotalAmountTZS = this.customerServiceList.reduce(
              (total, service) => {
                return total + parseFloat(service.TotalAmountTZS);
              },
              0
            );

            this.TotalAmountUSD = this.customerServiceList.reduce(
              (total, service) => {
                return total + parseFloat(service.TotalAmountUSD);
              },
              0
            );
          }

          if (this.customerServiceList.length == 0) {
            this.toastr.error(
              "There is no service List for this customer",
              "Error"
            );
          }
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  handleValueChangedYear(e) {
    if (e.value && this.invoiceCreateForm.get("month").value) {
      this.getCustomerServiceList();
    }
  }

  onMonthChange(e) {
    if (e.value && this.invoiceCreateForm.get("year").value) {
      this.getCustomerServiceList();
    }
  }
  onCustomerNameChange(e) {
    if (e.value) {
      this.getCustomeInformantion(e.value);
    }

    if (
      e.value &&
      this.invoiceCreateForm.get("year").value &&
      this.invoiceCreateForm.get("month").value
    ) {
      this.getCustomerServiceList();
    }
  }

  getCustomeInformantion(payerName) {
    const data = {
      requestType: "SP_CUSTOMER_GET_DEATILS",
      cusNo: payerName,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data[0];
          this.invoiceCreateForm.get("phone").setValue(data.mobileNo);
          this.invoiceCreateForm.get("email").setValue(data.email);
          this.invoiceCreateForm.get("billDescription").setValue(data.details);
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  getCustomerList() {
    const data = {
      requestType: "SP_CUSTOMER_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.customerList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onRevenueSourceChanged(e) {
    const id = e.value;


    // if (
    //   id == this.vipId ||
    //   id === this.conDutyFreeShopServCatID ||
    //   id === this.conAirCraftcateringServiceServCatID ||
    //   id === this.conOtherGroindHandlerCatID ||
    //   id === this.conOtherShopAndServCatID
    // )
    if (
      id == this.vipId
    ) {
      this.hideAmount = true;
    } else {
      this.hideAmount = false;
    }
    if (e.value == this.vipId) {
      this.isVIPRevenueSource = true;
    } else {
      this.isVIPRevenueSource = false;
      this.customerServiceList = [];
    }

    if (
      e.value == this.vipId &&
      this.invoiceCreateForm.get("year").value &&
      this.invoiceCreateForm.get("month").value
    ) {
      this.getCustomerServiceList();
    }
  }
  printInvoice(invoiceId) {
    this.router.navigate(["/invoice-print"], {
      queryParams: { invoiceId: invoiceId },
      queryParamsHandling: "merge",
    });
  }

  getCustomerNumber(value) {
    const customer = this.customerList.filter((name) => name.Name == value);
    return customer ? customer[0].cus_no : "";
  }
  getCustomerName(value) {
    const customer = this.customerList.filter((name) => name.cus_no == value);
    return customer ? customer[0].Name : "";
  }

  updateInnvoice() {
    if (!this.invoiceId) {
      this.toastr.error("Invoice Id is required");
      return;
    }

    let serviceList = [];

    if (this.customerServiceList.length != 0) {
      serviceList = this.customerServiceList.map((service) => {
        return {
          id: service.Id,
          name: service.serviceProvidedTo,
          date: service.serviceDate,
          amount: service.serviceAmount,
        };
      });
    }

    if (this.isVIPRevenueSource && this.customerServiceList.length != 0) {
      if (
        this.invoiceCreateForm.get("currency").value == this.InitialCurrency
      ) {

    
        const TotalAmountInTZS = this.customerServiceList.reduce((total, service) => {
          return total + parseFloat(service.TotalAmountInTZS);
        }, 0);

        this.invoiceCreateForm.get("billAmount").setValue(TotalAmountInTZS);
      }else
      {
        const TotalAmountInUSD = this.customerServiceList.reduce((total, service) => {
          return total + parseFloat(service.TotalAmountInUSD);
        }, 0);

        this.invoiceCreateForm.get("billAmount").setValue(TotalAmountInUSD);
      }
    }

    if (
      this.isVIPRevenueSource &&
      (!this.invoiceCreateForm.get("month").value ||
        !this.invoiceCreateForm.get("year").value)
    ) {
      this.toastr.error("Month and Year are required");
      return;
    }

    if (
      !this.isVIPRevenueSource &&
      !this.invoiceCreateForm.get("invoiceDate").value
    ) {
      this.toastr.error("Invoice Date is required");
      return;
    }

    if (
      !this.invoiceCreateForm.get("revenueSource").value ||
      !this.invoiceCreateForm.get("billAmount").value ||
      !this.invoiceCreateForm.get("billDescription").value
    ) {
      this.toastr.error(
        "payerName, Revenue Source, Invoice Amount and Invoice description are required"
      );
      return;
    }

    if (!this.isVIPRevenueSource) {
      this.invoiceCreateForm.get("month").setValue("");
      this.invoiceCreateForm.get("year").setValue("");
    }
    const payerName = this.getCustomerName(
      this.invoiceCreateForm.get("payerName").value
    );
    const rawRequest = {
      requestType: "INVOICES_UPDATE",
      invoiceId: this.invoiceId,
      payerName: payerName,
      revenueSources: this.invoiceCreateForm.get("revenueSource").value,
      date: this.invoiceCreateForm.get("invoiceDate").value,
      month: this.invoiceCreateForm.get("month").value,
      year: this.invoiceCreateForm.get("year").value,
      currency: this.invoiceCreateForm.get("currency").value,
      amount: this.invoiceCreateForm.get("billAmount").value,
      description: this.invoiceCreateForm.get("billDescription").value,
    };

    this.spinner.show();
    this.utilities.postServiceCall(rawRequest).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();
        this.createdInvoiceDatasource = null;
        if (servRes.statusId == 2000) {
          this.createdInvoiceDatasource = servRes.data[0];
          this.toastr.success("Invoice is updated successfully.");
          // this.showInvoiceAlert = true;

          this.billItems = [];
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  deleteInvoice() {
    const data = {
      requestType: "INVOICES_DELETE",
      invoiceNo: this.Invoice_number,
      invoiceID: this.invoiceId,
    };

    const result = confirm(
      "Are you sure you want to Delete this Invoice?",
      "Deleteion Approval"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const servRes = res.json();
            this.spinner.hide();
            if (servRes.statusId == 2000) {
              this.toastr.success(servRes.statusMessage, "Success");
              this.router.navigate(["/genereted-invoices"]);
            } else {
              this.toastr.error(servRes.statusMessage, "Request Failed");
            }
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong, failed to create the bill."
            );
          }
        );
      }
    });
  }
  createInvoice() {
    let serviceList = [];

    if (this.customerServiceList.length != 0) {
      serviceList = this.customerServiceList.map((service) => {
        return {
          id: service.Id,
          name: service.serviceProvidedTo,
          date: service.serviceDate,
          amount: service.serviceAmount,
        };
      });
    }

    if (this.isVIPRevenueSource && this.customerServiceList.length != 0) {
      if (
        this.invoiceCreateForm.get("currency").value == this.InitialCurrency
      ) {

        const TotalAmountInTZS = this.customerServiceList.reduce((total, service) => {
          return total + parseFloat(service.TotalAmountInTZS);
        }, 0);

        this.invoiceCreateForm.get("billAmount").setValue(TotalAmountInTZS);
      }else
      {
        const TotalAmountInUSD = this.customerServiceList.reduce((total, service) => {
          return total + parseFloat(service.TotalAmountInUSD);
        }, 0);

        this.invoiceCreateForm.get("billAmount").setValue(TotalAmountInUSD);
      }
    }


    Object.keys(this.invoiceCreateForm.controls).forEach((key) => {
      const control = this.invoiceCreateForm.get(key);
      control.markAsTouched();
    });

    const phone = this.invoiceCreateForm.get("phone");
    if (!phone.value) {
      this.toastr.error("Phone number is required", "Error");
      return;
    }
    if (phone.value && phone.invalid) {
      this.toastr.error(
        "The phone number must start with '07' or '06' and be exactly 10 digits.",
        "Invalid Phone Number"
      );
      return;
    }

    if (!this.invoiceCreateForm.get("billAmount").value && !this.hideAmount) {
      this.toastr.error("Please enter the Invoice amount.");
      return;
    }

    if (this.invoiceCreateForm.invalid) {
      this.toastr.error(
        "Invoice can not be created, required fields are empty"
      );

      return;
    }

    if (
      this.isVIPRevenueSource &&
      (!this.invoiceCreateForm.get("month").value ||
        !this.invoiceCreateForm.get("year").value)
    ) {
      this.toastr.error("Month and Year are required");
      return;
    }

    if (this.isVIPRevenueSource && serviceList.length == 0) {
      this.toastr.error("There is no Service List");
      return;
    }

    if (!this.isVIPRevenueSource) {
      this.invoiceCreateForm.get("month").setValue("");
      this.invoiceCreateForm.get("year").setValue("");
    }

    if (this.invoiceCreateForm.get("billExpireDate").value == null) {
      const dateString =
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2);
      this.invoiceCreateForm.get("billExpireDate").patchValue(dateString);
    } else {
      const dateString =
        new Date(
          this.invoiceCreateForm.get("billExpireDate").value
        ).getFullYear() +
        "-" +
        (
          "0" +
          (new Date(
            this.invoiceCreateForm.get("billExpireDate").value
          ).getMonth() +
            1)
        ).slice(-2) +
        "-" +
        (
          "0" +
          new Date(this.invoiceCreateForm.get("billExpireDate").value).getDate()
        ).slice(-2);
      this.invoiceCreateForm.get("billExpireDate").patchValue(dateString);
    }

    const payerName = this.getCustomerName(
      this.invoiceCreateForm.get("payerName").value
    );
    const rawRequest = {
      requestType: "INVOICE_CREATE",
      payerName: payerName,
      revenueSources: this.invoiceCreateForm.get("revenueSource").value,
      date: this.invoiceCreateForm.get("invoiceDate").value,
      month: this.invoiceCreateForm.get("month").value,
      year: this.invoiceCreateForm.get("year").value,
      currency: this.invoiceCreateForm.get("currency").value,
      amount: this.invoiceCreateForm.get("billAmount").value,
      description: this.invoiceCreateForm.get("billDescription").value,
      payerMobile: this.invoiceCreateForm.get("phone").value,
      payerEmail: this.invoiceCreateForm.get("email").value,
      paymentOption: this.invoiceCreateForm.get("paymentType").value,
      expireDate: this.invoiceCreateForm.get("billExpireDate").value,

      servicesList: serviceList,
    };


    this.spinner.show();
    this.utilities.postServiceCall(rawRequest).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();
        this.createdInvoiceDatasource = null;
        if (servRes.statusId == 2000) {
          this.createdInvoiceDatasource = servRes.data;

          this.patchValue(servRes.data, payerName, serviceList);
          // this.showInvoiceAlert = true;
          // this.showControlNumber = true;
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  patchValue(data, payerName, serviceList) {
    const encriptInvoiceData = {
      requestType: "BILLS_CREATE",
      billStatus: "1",
      payerName: payerName,
      serviceList: serviceList,
      isControlNumberSubmitted: false,
      invoiceId: data.ID,
      invoiceNo: data.INVOICE_NUMBER,
      date: this.invoiceCreateForm.get("invoiceDate").value,
      currency: this.invoiceCreateForm.get("currency").value,
      amount: this.invoiceCreateForm.get("billAmount").value
        ? this.invoiceCreateForm.get("billAmount").value
        : data.AMOUNT,
      description: this.invoiceCreateForm.get("billDescription").value,
      payerMobile: this.invoiceCreateForm.get("phone").value,
      payerEmail: this.invoiceCreateForm.get("email").value,
      paymentOption: this.invoiceCreateForm.get("paymentType").value,
      expireDate: this.invoiceCreateForm.get("billExpireDate").value,
      payerTelephone: this.invoiceCreateForm.get("phone").value,

      spId: this.institutionCode,
      spBillId: data.INVOICE_NUMBER,

      payerId: this.invoiceCreateForm.get("payerName").value,

      billItems: [
        {
          category: this.invoiceCreateForm.get("revenueSource").value,
          amount: this.invoiceCreateForm.get("billAmount").value
            ? this.invoiceCreateForm.get("billAmount").value
            : data.AMOUNT,
          description: this.invoiceCreateForm.get("billDescription").value,
        },
      ],
    };
    sessionStorage.setItem(
      AppSettings.invoiceKey,
      this.encryptionService.encryptString(encriptInvoiceData)
    );

    this.toastr.success("Invoice is created successfully");
    this.router.navigate(["/preview-invoice"]);
  }
  onPopupClose() {
    this.hideUpdateBillButton = true;
    this.showControlNumber = false;
    this.getCurrency();
  }
  getBillDetailsForUpdate() {}

  onFormReset() {
    this.amount = 0;
    this.billItemsDatasource = [];
    this.invoiceCreateForm.reset();
    this.invoiceCreateForm
      .get("institutionName")
      .patchValue(this.institutionName);
    this.invoiceCreateForm
      .get("institutionCode")
      .patchValue(this.institutionCode);
  }

  getCategoryBySp() {
    const data = {
      requestType: "SP_SERVICE_CATEGORY_LIST",
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.spCatList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Categories",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getCurrency() {
    const data = {
      requestType: "SP_CURRENCY_LIST",
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.currencyDts = srvRes.data;

          this.invoiceCreateForm
            .get("currency")
            .setValue(srvRes.data[0].currency);
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Currency",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  addNewBillItem() {
    this.showBillItemsDialog = true;
    this.selectedCurrency = this.invoiceCreateForm.get("currency").value;
    this.accDistributionDatasource = [];
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      id: "button-style",
      options: {
        text: "Add Item",
        type: "default",
        icon: "fa fa-plus",
        onClick: this.addNewBillItem.bind(this),
      },
    });
  }

  onServiceCategoryChanged(e) {
    this.accDistributionDatasource = [];

    if (this.invoiceCreateForm.get("currency").invalid) {
      this.toastr.error("Please select Currency first");
      return;
    }

    let counter = 0;
    for (const iterator of this.spCatList) {
      if (iterator.catId == e.value) {
        for (const item of iterator.accDistribution) {
          if (item.currency == this.invoiceCreateForm.get("currency").value) {
            for (const i of item.distribution) {
              this.accDistributionDatasource.push({
                id: counter++,
                accNo: i.accNo,
                amount: i.amount,
                description: i.description,
                currency: this.invoiceCreateForm.get("currency").value,
                amtOptCode: iterator.amtOptCode,
                distOptCode: iterator.distOptCode,
                categoryId: e.value,
                categoryName: iterator.catName,
              });
            }
            break;
          }
        }
        break;
      }
    }
  }

  addBillCategoryItemToMainDatasource() {
    let totalAmount = 0;
    let data = {};

    for (const iterator of this.accDistributionDatasource) {
      totalAmount += +iterator.amount;
    }

    for (const iterator of this.accDistributionDatasource) {
      data = {
        categoryId: iterator.categoryId,
        categoryName: iterator.categoryName,
        amount: totalAmount,
        distribution: this.accDistributionDatasource,
        description: `${iterator.categoryName} for ${
          this.invoiceCreateForm.get("payerName").value
        }`,
      };
    }
    this.billItemsDatasource.push(data);
    this.showBillItemsDialog = false;
  }
}

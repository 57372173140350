import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SharedClassComponent } from "../configurations/shared-class/shared-class.component";
import { confirm } from "devextreme/ui/dialog";

@Component({
  selector: "app-service-provider",
  templateUrl: "./service-provider.component.html",
  styleUrls: ["./service-provider.component.scss"],
})
export class ServiceProviderComponent
  extends SharedClassComponent
  implements OnInit
{
  //variables defination
  paramswinpnl: boolean = false;

  categoryInfo: FormGroup;
  endpointcall: string;
  paramsDataSets: any;
  param_id: any;
  params_actions: any;
  response: any;
  title: string = "Service Provider Category";
  model_name: string = "sp/category";
  isAllowedToAdd: Boolean = false;
  isAllowedToDelete: Boolean = false;
  isAllowedToUpdate: Boolean = false;
  revenueTypeList: any;
  catId: any;
  showUpdateButton: boolean;

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle("Service Provider Category");

    this.categoryInfo = new FormGroup({
      spName: new FormControl(sessionStorage.getItem("spName")),
      catName: new FormControl("", Validators.compose([Validators.required])),
      catTypeId: new FormControl("", Validators.compose([Validators.required])),
    });
    this. getUseWithPermissions();
    this.getServiceProviderCategories();
    this.getServiceProviders();
    this.observerCall();
    this.getRevenueTypeList();
  }

  getRevenueTypeList() {
    const data = {
      requestType: "SP_SERVICE_CATEGORY_TYPES_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.revenueTypeList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Categories",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getUseWithPermissions() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;

            this.isAllowedToAdd = pemsn.some(
              (permission) =>
                permission.permission === "Add Service Categories (a)" &&
                permission.permissionName === "true"
            );

            this.isAllowedToDelete = pemsn.some(
              (permission) =>
                permission.permission === "Delete Service Categories (a)" &&
                permission.permissionName === "true"
            );
            this.isAllowedToUpdate = pemsn.some(
              (permission) =>
                permission.permission === "Update Service Categories (a)" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }
  refreshDataGrid() {
    this.getServiceProviderCategories();
    this.getServiceProviders();
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "New Category",
          type: "default",
          icon: "fa fa-plus",
          onClick: this.addCategory.bind(this),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "default",
          onClick: this.refreshDataGrid.bind(this),
        },
      }
    );
  }

  previewCatDetails(e) {
    if (e.data) {
      const data = e.data;
      this.categoryInfo
        .get("catTypeId")
        .setValue(data.catTypeId ? Number(data.catTypeId) : 401),
        this.categoryInfo.get("catName").setValue(data.catName);
      this.catId = e.data.catId;
      this.showUpdateButton = true;
      this.paramswinpnl = true;
    } else {
      this.toastr.error("Failed to fetch Data");
    }
  }
  addCategory() {
    if(!this.isAllowedToAdd){
      this.toastr.warning("You don't have permissions", "Failed")
        return;
      }


      this.categoryInfo
      .get("catTypeId")
      .setValue(""),
      this.categoryInfo.get("catName").setValue("");
    this.dialogHeight = 350;
    this.showUpdateButton = false;
    this.paramswinpnl = true;
    this.hideSaveButton = true;
    this.hideButton = false;
    this.showAuthorizeUserButton = false;
  }

  categoryCreate() {
    if (this.categoryInfo.invalid) {
      this.toastr.error("Category Name and Category Type are required");
      return;
    }
    const data = {
      requestType: "SP_SERVICE_CATEGORY_CREATE",
      catSpId: sessionStorage.getItem("spId"),
      catTypeId: this.categoryInfo.get("catTypeId").value,
      catName: this.categoryInfo.get("catName").value,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.getServiceProviderCategories();
          this.paramswinpnl = false;
          this.toastr.success(srvRes.statusMessage);

          this.spinner.hide();
        }
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  categoryUpdate() {
    if (this.categoryInfo.invalid) {
      this.toastr.error("Category Name and Category Type are required");
      return;
    }
    const data = {
      requestType: "SP_SERVICE_CATEGORY_UPDATE",
      catId: this.catId,
      catTypeId: this.categoryInfo.get("catTypeId").value,
      catName: this.categoryInfo.get("catName").value,

    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.getServiceProviderCategories();
          this.paramswinpnl = false;
          this.showUpdateButton=false;
          this.toastr.success(srvRes.statusMessage);
          this.spinner.hide();
        }
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-customer-statement-report",
  templateUrl: "./customer-statement-report.component.html",
  styleUrls: ["./customer-statement-report.component.scss"],
})
export class CustomerStatementComponent
  extends SharedClassComponent
  implements OnInit
{
  title: string = "Customer Statement Report";
  oneDayAhead = new Date();
  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];
  customerList: any;
  selectedEndDate: string;
  selectedStartDate: string;

  ngOnInit() {
    this.appInfo.setTitle(this.title);

    this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
    this.paramwinfrm = new FormGroup({
      customerId: new FormControl(""),

      startDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
      endDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
    });

    const dd = String(this.today.getDate()).padStart(2, "0");
    const mm = String(this.today.getMonth() + 1).padStart(2, "0");
    const yyyy = this.today.getFullYear();

    this.currentDate = yyyy + "-" + mm + "-" + dd;

    this.observerCall();
    this.currentUser = this.authService.getUserDetails().username;
    this.getCustomerList();
  }

  getCustomerList() {
    const data = {
      requestType: "SP_CUSTOMER_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.customerList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getCustomerByRange() {

    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and End Date are required.");
      return;
    } 

   
    const startDate = new Date(this.paramwinfrm.get("startDate").value);
    const startDatestring =
      startDate.getFullYear() +
      "-" +
      String(startDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(startDate.getDate()).padStart(2, "0");
    

    const endDate = new Date(this.paramwinfrm.get("endDate").value);
    const endDatestring =
      endDate.getFullYear() +
      "-" +
      String(endDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(endDate.getDate()).padStart(2, "0");
    

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than End Date.");
      this.spinner.hide();
      return;
    }    
    this.spinner.show();
    
    const data = {
      requestType: "CUSTOMER_STATEMENT_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,

      customerNo:
        this.paramwinfrm.get("customerId").value != null
          ? this.paramwinfrm.get("customerId").value.toString()
          : "",
    };


  

    this.selectedStartDate = startDatestring;
    this.selectedEndDate = endDatestring;
    this.datasource = [];
    this.utilities.postServiceCall(data).subscribe(
      (response) => {
        const res = response.json();

        if (res.statusId == 2000) {
          const data = res.data;

          

          if (data.currencyData) {
            this.datasource =data.currencyData;
          }
        } else {
          this.toastr.error(res.statusMessage, "Error");
        }

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Print",
        type: "default",
        icon: "fa fa-print",
        onClick: this.printReport.bind(this),
      },
    });
  }

  getTitle() {
    const customerID = this.paramwinfrm.get("customerId").value;

    let customerName;
    if (this.customerList) {
      const selectedCustomer = this.customerList.find(
        (customer) => customer.cus_no === customerID
      );
      customerName = selectedCustomer ? ` for ${selectedCustomer.Name}` : "";
    }

    const dateRange =
      this.selectedStartDate && this.selectedEndDate
        ? ` between ${this.selectedStartDate} and ${this.selectedEndDate}`
        : "";

    const title = `${this.title || ""}${customerName}${dateRange}`.trim();

    return title || this.title;
  }
}

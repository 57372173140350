import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-customer-balance-summary-report",
  templateUrl: "./customer-balance-summary-report.component.html",
  styleUrls: ["./customer-balance-summary-report.component.scss"],
})
export class CustomerBalanceSummaryComponent
  extends SharedClassComponent
  implements OnInit
{
  title: string = "Customer Balance Summary Report";
  oneDayAhead = new Date();
  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];

  selectedEndDate: string;
  selectedStartDate: string;
  customerData=[];
  aggregatedTotals=[];

  ngOnInit() {
    this.appInfo.setTitle(this.title);

    this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
    this.paramwinfrm = new FormGroup({


      startDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
      endDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
    });

    const dd = String(this.today.getDate()).padStart(2, "0");
    const mm = String(this.today.getMonth() + 1).padStart(2, "0");
    const yyyy = this.today.getFullYear();

    this.currentDate = yyyy + "-" + mm + "-" + dd;

    this.observerCall();
    this.currentUser = this.authService.getUserDetails().username;

  }

  expandedCustomers: { [key: string]: boolean } = {};

  toggleCustomer(customerName: string) {
    this.expandedCustomers[customerName] = !this.expandedCustomers[customerName];
  }

  isExpanded(customerName: string): boolean {
    return this.expandedCustomers[customerName] || false;
  }
  getRowSpan(customer: any): number {
    return customer.currencyData.reduce((total, currency) => total + currency.revenueData.length, 0);
  }
  

  toggleAll(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.customerData.forEach(customer => {
      this.expandedCustomers[customer.customerName] = isChecked;
    });
  }
  

  areAllExpanded(): boolean {
    return this.customerData.every(customer => this.isExpanded(customer.customerName));
  }



  getCustomerByRange() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and End Date are required.");
      return;
    } 

   
    const startDate = new Date(this.paramwinfrm.get("startDate").value);
    const startDatestring =
      startDate.getFullYear() +
      "-" +
      String(startDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(startDate.getDate()).padStart(2, "0");
    

    const endDate = new Date(this.paramwinfrm.get("endDate").value);
    const endDatestring =
      endDate.getFullYear() +
      "-" +
      String(endDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(endDate.getDate()).padStart(2, "0");
    

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than End Date.");
      this.spinner.hide();
      return;
    }    

    
    const data = {
      requestType: "CUSTOMER_BALANCE_SUMMARY_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,

     
    };


  

    this.selectedStartDate = startDatestring;
    this.selectedEndDate = endDatestring;
    this.datasource = [];
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (response) => {
        const res = response.json();

        if (res.statusId == 2000) {
          const data = res.data;

          

          if (data.customerData) {
            this.customerData =data.customerData;
          }

          if (data.aggregatedTotals) {
            this.aggregatedTotals =data.aggregatedTotals;
          }
        } else {
          this.toastr.error(res.statusMessage, "Error");
        }

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Print",
        type: "default",
        icon: "fa fa-print",
        onClick: this.printReport.bind(this),
      },
    });
  }

  getTitle() {
  

    const dateRange =
      this.selectedStartDate && this.selectedEndDate
        ? ` between ${this.selectedStartDate} and ${this.selectedEndDate}`
        : "";

    const title = `${this.title || ""}${dateRange}`.trim();

    return title || this.title;
  }



  printCustomerBalanceReport() {
    let popUpWindow;
    const innerContents = document.getElementById("report-container-new").innerHTML;
    popUpWindow = window.open(
      "",
      "_blank",
      "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
    );
    
    popUpWindow.document.write(`
      <html lang="en">
        <head>
          <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
          <style>
            body {
              font-size: 14px;
              line-height: 1.42857143;
              color: #333;
              margin: 0;
              padding: 20px;
              font-family: 'Montserrat', sans-serif;
            }
  
            .logo__block {
              text-align: center;
              margin-bottom: 20px;
            }
  
            .logo__block img {
              width: 150px;
              height: 100px;
              margin: 0 auto;
            }
  
            .logo__block h2, .logo__block h4 {
              margin: 10px 0;
              text-align: center;
            }
  
            .table-container {
              margin: 20px 0;
            }
  
            table {
              border-collapse: collapse;
              width: 100%;
              table-layout: auto;
            }
  
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
              overflow: visible; /* Changed from auto to visible */
              word-wrap: break-word;
            }
  
            td.amount {
              text-align: right;
              padding-right: 15px !important; /* Added extra padding for amounts */
              white-space: nowrap; /* Prevent wrapping of amounts */
              min-width: 120px; /* Minimum width for amount column */
            }
  
            th {
              background-color: #f2f2f2; /* Changed from green to light gray */
              color: #333; /* Changed text color to dark */
              font-weight: bold;
            }
  
            tr.subtotal {
              background-color: #f8f8f8;
              font-weight: bold;
            }
  
            tr.grand-total {
              background-color: #eee;
              font-weight: bold;
            }
  
            .printed-by-info {
              position: fixed;
              bottom: 0;
              width: 98%;
              background-color: white;
              border-top: 1px solid black;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 5px 0;
            }
  
            @media print {
              @page {
                size: A4;
                margin: 0mm;
              }
  
              body {
                margin: 0 auto;
                line-height: 1em;
                word-spacing: 2px;
                letter-spacing: 0.2px;
                font-size: 12px;
                width: 100%;
                padding: 20px;
              }
  
              thead {
                display: table-header-group;
              }
  
              tfoot {
                display: table-footer-group;
              }
  
              tr {
                page-break-inside: avoid;
              }
  
              th {
                background-color: #f2f2f2 !important; /* Maintained light gray in print */
                color: #333 !important;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
  
              .logo__block {
                page-break-inside: avoid;
                margin-bottom: 30px;
              }
  
              th, td {
                padding: 5px !important;
              }
  
              td.amount {
                padding-right: 15px !important;
              }
  
              .printed-by-info {
                position: fixed;
                bottom: 0;
                width: 98%;
                background-color: white;
                border-top: 1px solid black;
                padding: 5px 0;
              }
  
              /* Column widths for better amount display */
              th:last-child,
              td:last-child {
                min-width: 120px;
                width: auto;
              }
  
              /* Remove any unwanted margins/padding */
              .container, .table-container {
                margin: 0 !important;
                padding: 0 !important;
              }
  
              /* Ensure rowspan works correctly */
              td[rowspan] {
                vertical-align: middle;
              }
            }
          </style>
        </head>
        <body onload="window.print()">
          ${innerContents}
          <div class="printed-by-info">
            <div>
              <b>Printed By: ${this.getCurrentUserDetails()}</b>
            </div>
            <div>
              <b>Date: ${this.getCurrentDateForPrint()}</b>
            </div>
          </div>
        </body>
      </html>
    `);
    
    popUpWindow.document.close();
  }
  printCustomerBalanceRepo4rt() {
    let popUpWindow;
    const innerContents = document.getElementById("report-container-new").innerHTML;
    popUpWindow = window.open(
      "",
      "_blank",
      "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
    );
    popUpWindow.document.write(`
      <html lang="en">
        <head>
          <style>
            @media print {
              @page {
                size: A4;
                margin: 10mm;
              }
  
              @page :first {
                margin-top: 0;
              }
            }
  
            body {
              margin: 0;
              padding: 10px 20px;
              font-family: Arial, sans-serif;
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
            }
  
            .logo__block {
              text-align: center;
              margin-bottom: 20px;
              width: 100%;
            }
  
            .logo__block img {
              width: 150px;
              height: 100px;
              margin: 0 auto;
            }
  
            .table-container {
              width: 100%;
              margin: 0;
              padding: 0;
            }
  
            table {
              width: 100%;
              border-collapse: collapse;
              table-layout: fixed;
            }
  
            th, td {
              border: 1px solid #ddd;
              padding: 5px !important;
              text-align: left;
              word-wrap: break-word;
            }
  
            th:nth-child(1), td:nth-child(1) { width: 25%; }
            th:nth-child(2), td:nth-child(2) { width: 15%; }
            th:nth-child(3), td:nth-child(3) { width: 40%; }
            th:nth-child(4), td:nth-child(4) { width: 20%; }
  
            td.amount {
              text-align: right;
              padding-right: 15px !important;
              white-space: nowrap;
            }
  
            th {
              background-color: #f2f2f2 !important;
              color: #333;
              font-weight: bold;
            }
  
            tr.subtotal {
              background-color: #f8f8f8 !important;
            }
  
            tr.grand-total {
              background-color: #eee !important;
            }
  
            .printed-by-info {
              position: fixed;
              bottom: 0;
              width: calc(100% - 40px);
              background-color: white;
              border-top: 1px solid black;
              display: flex;
              justify-content: space-between;
              padding: 5px 0;
            }
          </style>
        </head>
        <body onload="window.print()">
          <div style="visibility: hidden; display: none;">
            <div style="display: none;" id="about:blank"></div>
          </div>
          ${innerContents}
          <div class="printed-by-info">
            <div>
              <b>Printed By: ${this.getCurrentUserDetails()}</b>
            </div>
            <div>
              <b>Date: ${this.getCurrentDateForPrint()}</b>
            </div>
          </div>
          <script>
            window.onbeforeprint = function() {
              document.title = "";
            };
          </script>
        </body>
      </html>
    `);
    
    popUpWindow.document.close();
  }
}


import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-cancelled-bill-report',
  templateUrl: './cancelled-bill-report.component.html',
  styleUrls: ['./cancelled-bill-report.component.scss']
})
export class CancelledBillReportComponent extends SharedClassComponent implements OnInit {
  title = 'Cancelled Bills Report';
  oneDayAhead = new Date();
  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];
  customerList: any;
  departmentList: any;
  branchList: any;
  currencyList = ["TZS", "USD"];
  spCatList: any;
  revenueTypeList: any;
  ngOnInit() {
        //  this.spinner.show();
        this.toastr.info('Data loading in progress', 'Please wait');
        // sets the current page title in index.html title tag
        this.appInfo.setTitle(this.title);

        this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
        this.paramwinfrm = new FormGroup({
          customerId: new FormControl(""),
          department: new FormControl(""),
          branch: new FormControl(""),
          revenueSource: new FormControl(""),
          currency: new FormControl(""),
          revenueType: new FormControl(""),
          startDate: new FormControl(this.today, Validators.compose([Validators.required])),
          endDate: new FormControl(this.today, Validators.compose([Validators.required]))
        });

        const dd = String(this.today.getDate()).padStart(2, '0');
        const mm = String(this.today.getMonth() + 1).padStart(2, '0'); // January is 0
        const yyyy = this.today.getFullYear();

        //  this.currentDate = dd + '-' + mm + '-' + yyyy;
        this.currentDate = yyyy + '-' + mm + '-' + dd;
        this.getCancelleddBillRange();
        // Controls the datagrid height and max rows to display
        this.observerCall();
        this.currentUser = this.authService.getUserDetails().username;
        this.getCustomerList();
        this.getBranchList();
        this.getDepartmentList();
        this.getCategoryBySp()
        this.getRevenueTypeList();
      }
    
    


      getRevenueTypeList() {
        const data = {
          requestType: "SP_SERVICE_CATEGORY_TYPES_LIST",
         
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const srvRes = res.json();
    
            if (srvRes.statusId == 2000) {
              this.revenueTypeList = srvRes.data;
              this.spinner.hide();
            } else {
              this.toastr.error(
                "Failed to load Service Categories",
                srvRes.statusMessage
              );
            }
            this.spinner.hide();
          },
          (error) => {
            this.toastr.error(error);
            this.spinner.hide();
          }
        );
      }
    
    

      getCategoryBySp() {
        const data = {
          requestType: "SP_SERVICE_CATEGORY_LIST",
          spId: sessionStorage.getItem('spId'),
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const srvRes = res.json();
    
            if (srvRes.statusId == 2000) {
              this.spCatList = srvRes.data;
              this.spinner.hide();
            } else {
              this.toastr.error(
                "Failed to load Service Categories",
                srvRes.statusMessage
              );
            }
            this.spinner.hide();
          },
          (error) => {
            this.toastr.error(error);
            this.spinner.hide();
          }
        );
      }
    
    
    
    
      getCustomerList() {
        const data = {
          requestType: "SP_CUSTOMER_LIST",
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.customerList = response.data;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    
      getBranchList() {
        const data = {
          requestType: "BRANCH_LIST",
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.branchList = response.data;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    
      getDepartmentList() {
        const data = {
          requestType: "USERGROUP_LIST",
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.departmentList = response.data;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }

  getCancelleddBillRange() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and End Date are required.");
      return;
    } 

   
    const startDate = new Date(this.paramwinfrm.get("startDate").value);
    const startDatestring =
      startDate.getFullYear() +
      "-" +
      String(startDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(startDate.getDate()).padStart(2, "0");
    

    const endDate = new Date(this.paramwinfrm.get("endDate").value);
    const endDatestring =
      endDate.getFullYear() +
      "-" +
      String(endDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(endDate.getDate()).padStart(2, "0");
    

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than End Date.");
      this.spinner.hide();
      return;
    }    
    this.spinner.show();
    const data = {
              "requestType": "CANCELLED_BILLS_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
      spId:  sessionStorage.getItem('spId'),
      customerId: this.paramwinfrm.get("customerId").value != null ? this.paramwinfrm.get("customerId").value.toString() : "",
      department: this.paramwinfrm.get("department").value != null ? this.paramwinfrm.get("department").value.toString() : "",
      branch: this.paramwinfrm.get("branch").value != null ? this.paramwinfrm.get("branch").value.toString() : "",
      revenueSource: this.paramwinfrm.get("revenueSource").value,
      currency: this.paramwinfrm.get("currency").value,
      revenueType:this.paramwinfrm.get("revenueType").value != null ? this.paramwinfrm.get("revenueType").value.toString() : "",
    };

    this.utilities.postServiceCall(data).subscribe(
        response => {
          const res = response.json();
          this.datasource = res.data.billDetails;
          this.spinner.hide();
          this.toastr.info('Data has finished loading', 'Process Completed');
        },
        error => {
          this.spinner.hide();
          this.toastr.error(
            'Error occurred while processing the request',
            'Request Failed'
          );
        }
      );
  }

  getCancelleddBillsByDateRange() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and End Date are required.");
      return;
    } 

   
    const startDate = new Date(this.paramwinfrm.get("startDate").value);
    const startDatestring =
      startDate.getFullYear() +
      "-" +
      String(startDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(startDate.getDate()).padStart(2, "0");
    

    const endDate = new Date(this.paramwinfrm.get("endDate").value);
    const endDatestring =
      endDate.getFullYear() +
      "-" +
      String(endDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(endDate.getDate()).padStart(2, "0");
    

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than End Date.");
      this.spinner.hide();
      return;
    }    
    this.spinner.show();
    const data = {
              "requestType": "CANCELLED_BILLS_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
      spId:  sessionStorage.getItem('spId'),
      customerId: this.paramwinfrm.get("customerId").value != null ? this.paramwinfrm.get("customerId").value.toString() : "",
      department: this.paramwinfrm.get("department").value != null ? this.paramwinfrm.get("department").value.toString() : "",
      branch: this.paramwinfrm.get("branch").value != null ? this.paramwinfrm.get("branch").value.toString() : "",
      revenueSource: this.paramwinfrm.get("revenueSource").value,
      currency: this.paramwinfrm.get("currency").value,
      revenueType:this.paramwinfrm.get("revenueType").value != null ? this.paramwinfrm.get("revenueType").value.toString() : "",
    };
    this.utilities.postServiceCall(data).subscribe(
      response => {
        const res = response.json();
        this.datasource = res.data.billDetails;
        this.spinner.hide();
        this.toastr.info('Data has finished loading', 'Process Completed');
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
      }
    );
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: refresh_action.bind(this)
        }
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "Print",
          type: "default",
          icon: "fa fa-print",
          onClick: this.printReport.bind(this),
        },
      }
      
    );
  }

}
